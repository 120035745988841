.container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  margin-bottom: 1rem;
}

.category {
  border: 1px solid #244b91;
  border-radius: 10px;
  padding: 1rem;
  text-align: center;
}

.category > h3 {
  color: #244b91;
  font-size: 1.75rem;
}

.category li {
  list-style: none;
  font-size: 1.5rem;
}
