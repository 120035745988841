.central-title {
  margin: 2rem 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin: 0 1rem;
  gap: 2rem;
  margin-bottom: 0;
}

.selectGroup {
  display: flex;
  gap: 0.5rem;
}

@media screen and (max-width: 550px) {
  .selectGroup {
    flex-direction: column;
  }
}

.selectGroup > select {
  padding: 0.25 rem;
  border: 1px solid #1e4266;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
}
.selectGroup > button {
  background-color: #244b91;
  color: white;
  border: 1px solid #244b91;
  border-radius: 0.5rem;
  cursor: pointer;
}
.selectGroup > button:hover {
  background-color: white;
  border: 1px solid #244b91;
  color: #244b91;
  border-radius: 0.5rem;
}

.form_link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.75rem;
  align-items: center;
  color: white;
  background-color: #244b91;
  border: 1px solid #244b91;
  border-radius: 50%;
  height: 12rem;
  width: 12rem;
}
small {
  font-size: 0.75rem;
}

.form_link:hover {
  text-decoration: none;
  background-color: white;
  color: #244b91;
}

.result {
  display: flex;
  margin: auto;
  background-color: #244b91;
  border-radius: 0.5rem;
}

.no_category {
  display: flex;
  margin: auto;
  background-color: #244b91;
  border-radius: 0.5rem;
  color: white;
  padding: 1rem;
}

a:hover {
  text-decoration: none;
}

.notes {
  text-align: justify;
  margin: 0;
}

.notes h4 {
  color: #1e4266;
}

.cta_ticket {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 1rem 0;
}

.ticket_button {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: #244b91;
  color: white;
  border: 1px solid #244b91;
  border-radius: 2rem;
  cursor: pointer;
  padding: 1rem;
  font-weight: bold;
}

.ticket_button > p {
  margin: 0;
}

.ticket_button > i {
  font-size: 2.5rem;
}

/* .fa-ticket-alt{
  font-size: 2rem;
} */

.ticket_button:hover {
  background-color: white;
  border: 1px solid #244b91;
  color: #244b91;
}

.note {
  margin: 1rem;
  font-size: 1rem;
  text-align: center;
}
