@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css);
/* General Styles */
* {
  font-family: "Poppins-Regular", sans-serif;
  box-sizing: border-box;
  margin: 0px;
}
html {
  overflow-x: hidden;
}
body {
  padding: 0% !important;
  height: 100% !important;
  overflow-x: hidden;
}
.layout-styles {
  height: 100vh !important;
}
.container-fluid {
  padding: 0px !important;
}
.container {
  padding: 0px !important;
}
.navbar {
  padding: 0px !important;
}
.text-blue {
  color: #114189;
  font-weight: 700;
}
.bottom-border {
  border-bottom: 3px solid #dddddd;
}
.blue-bottom-border {
  border-bottom: 3px solid #114189;
}
h2 {
  font-size: 36px !important;
  font-family: "Cocogoose", sans-serif;
}
h3 {
  font-size: 56px;
  font-family: "Cocogoose", sans-serif;
}
.myh3 {
  font-size: 1.17em;
  font-weight: bold;
  font-family: "Cocogoose", sans-serif;
}
h4 {
  font-size: 32px !important;
  font-family: "Cocogoose", sans-serif;
}
h5 {
  font-size: 28px !important;
  font-family: "Cocogoose", sans-serif;
}
h6 {
  font-size: 24px !important;
  font-family: "Cocogoose", sans-serif;
}
p {
  font-size: 20px;
  padding: 0px !important;
}
.row {
  padding: 0px !important;
  width: 100%;
}
.separation-lines {
  height: 5px;
  width: 100%;
}
.f-right {
  float: right !important;
}
.tc-white {
  color: white;
}
.f-weigth-200 {
  font-weight: 200;
}
.f-weigth-600 {
  font-weight: 600;
}
.f-size-16 {
  font-size: 16px;
}
.w-50 {
  width: 50% !important;
}
.w-10 {
  width: 10% !important;
}
.justify-text {
  text-align: justify;
}
.center-text {
  text-align: center;
}
.content-center {
  justify-content: center;
}
.p-center {
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
.programs-center {
  position: relative;
  left: 50%;
  -webkit-transform: translate(-47%);
          transform: translate(-47%);
}
.p-left {
  position: relative;
  left: 50%;
  -webkit-transform: translate(-20%);
          transform: translate(-20%);
}
.text-gray {
  color: #77777a;
}

/* Navbar Styles. */

.my-navbar {
  background-color: white;
}
.my-navbar-brand {
  width: 40% !important;
  margin: 1% 0% 1% 2%;
}
.my-navbar-logo {
  width: 100% !important;
}
.my-navbar-nav {
  display: flex;
  justify-content: flex-end;
  width: 80%;
}

.my-navlink {
  margin-left: 5px;
}

/* Carousel Styles. */

.my-carousel-image {
  height: 50% !important;
}
@media (min-width: 300px) {
  .carousel-caption h3 {
    font-size: 1rem;
  }
  .carousel-caption p {
    font-size: 0.7rem;
  }
}
@media (min-width: 768px) {
  .carousel-caption h3 {
    font-size: 1.2rem;
  }
  .carousel-caption p {
    font-size: 1rem;
  }
}
@media (min-width: 992px) {
  .carousel-caption h3 {
    font-size: 1.3rem;
  }
  .carousel-caption p {
    font-size: 1.1rem;
  }
}
@media (min-width: 1200px) {
  .carousel-caption h3 {
    font-size: 2.5rem;
  }
  .carousel-caption p {
    font-size: 1.2rem;
  }
}

/* Stadistics Styles. */

.stadistics {
  background-color: #3d67a0;
  justify-content: center;
}
.stadistics-icon {
  color: white;
}
.stadistics-title {
  color: white;
  font-size: 3rem;
  text-align: center !important;
}
.stadistics-text {
  color: white;
  font-size: 2rem;
  text-align: center;
}

/* Programs Styles */

.programs-text {
  display: inline-block;
  justify-content: center;
  margin-right: 0 !important;
}
.programs-logo {
  width: 20%;
  height: 15%;
  float: right;
}
.programs-button {
  position: relative;
  left: 10%;
  -webkit-transform: translate(-20%);
          transform: translate(-20%);
}

/* Courses Styles */

.courses-title {
  float: right;
}
.courses-text {
  justify-content: center;
  margin-left: 0 !important;
  float: right;
}
.courses-logo {
  width: 20%;
  height: 12%;
  display: inline-block;
}
.courses-button {
  position: relative;
  left: 65%;
  -webkit-transform: translate(-80%);
          transform: translate(-80%);
}

/* Aliados Styles */

.aliados-row {
  height: 20%;
}
.aliado-logo {
  width: 20%;
  height: 15%;
}

/* MyFooter Styles */

.my-footer {
  background-color: #3d67a0;
}
.my-footer-logo {
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
.my-footer-title {
  justify-content: center;
}
.my-footer-link {
  color: white;
  padding: 0px !important;
  transition: color 1s;
}
.my-footer-link:hover {
  color: #fdd000;
  text-decoration: none;
}
.my-footer-sm {
  position: relative;
  left: 27%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
.my-footer-sm-logo {
  width: 20% !important;
}

.contact-info {
  display: flex;
  flex-direction: column;
}

/* Company View Styles */

.company-logo {
  height: 25%;
}

/* Plan Vacacional Styles */

.planilla-link {
  color: #114189;
  padding: 0px !important;
  transition: color 1s;
}
.planilla-link:hover {
  color: #fdd000;
  text-decoration: none;
}
.preventa {
  border-right: 2px solid #dddddd;
}
@media (max-width: 768px) {
  .plan-vacacional-img {
    height: 50%;
  }
  .preventa {
    border: none;
    margin-left: 8px;
  }
}

/* ICON SIZE */
.boostrap-icon {
  font-size: 10rem;
  color: rgb(16, 65, 138);
}

/* Font Type Neuropol-X-Rg headings */
@font-face {
  font-family: "Neuropol-X-Rg";
  src: local("Neuropol-X-Rg"), url(/static/media/Neuropol-X-Rg.e42ada49.ttf);
}
.font-face-nxrg {
  font-family: "Neuropol-X-Rg";
}

@font-face {
  font-family: "Cocogoose";
  src: local("Cocogoose_trial"), url(/static/media/Cocogoose_trial.d14035f6.otf);
}

.font-face-cocogoose {
  font-family: "Cocogoose" !important;
}

@font-face {
  font-family: "GothamRnd-Bold";
  src: local("GothamRnd-Bold"), url(/static/media/GothamRnd-Bold.ae8f19f4.TTF);
}
.font-face-gothamrnd-bold {
  font-family: "GothamRnd-Bold";
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url(/static/media/Poppins-Regular.de2dd933.otf);
}
.font-face-poppins-regular {
  font-family: "Poppins-Regular";
}

.headingText {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.headerText {
  font-size: 1.5rem !important;
}
/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .headingText {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .headerText {
    font-size: 2rem !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .headingText {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .headerText {
    font-size: 2.5rem !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .headingText {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .headerText {
    font-size: 3rem !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .headingText {
    font-size: 5rem;
    font-weight: 300;
    line-height: 1.2;
  }
}

/** Blue color */
.blue-bg-color {
  background-color: #1e4266;
}

.text-blue-color {
  color: #1e4266;
}

.bg-blue-badge {
  background-color: #114189;
}

.logo-tk {
  margin: 2rem 0;
}

.banner {
  display: flex;
  justify-content: center;
  /* width: 70vw; */
  margin: auto;
}

.banner img {
  width: 100vw;
}

.PlanVacacional_plan-logo__2V123 {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.PlanVacacional_plan-logo__2V123 > img {
  width: 70%;
}

.PlanVacacional_plan-info__3phtB {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 2rem 0;
  align-items: center;
  justify-content: center;
  color: #10418a;
}

.PlanVacacional_plan-info__3phtB > h3 {
  text-align: center;
}

.PlanVacacional_plan-cta__31-hL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 2rem 0;
}

.PlanVacacional_bold__171QC {
  margin: 0;
  font-family: "Cocogoose";
}

.PlanVacacional_plan-cta-item__l0GBq,
.PlanVacacional_academy-cta-item__etqaG {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 4rem;
  grid-gap: 1rem;
  gap: 1rem;
  min-width: 70%;
  border-radius: 2rem;
  color: #ffffff;
  background-color: #10418a;
  font-size: 1.5rem;
}

.PlanVacacional_plan-cta__31-hL a {
  color: white;
  text-decoration: none;
}

.PlanVacacional_academy-cta-item__etqaG {
  background-color: #d3001a;
}

.PlanVacacional_plan-cta-item__l0GBq:hover {
  background-color: #1e57ad;
}

.PlanVacacional_academy-cta-item__etqaG:hover {
  background-color: #ee3c3a;
}

.PlanVacacional_plan-details__2zL8V {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #10418a;
}

.PlanVacacional_plan-details__2zL8V > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.PlanVacacional_plan-details__2zL8V > div > div {
  text-align: center;
}

.PlanVacacional_academy__35QYx {
  color: #d3001a;
}

.PlanVacacional_division__3-tI3 {
  margin: 2rem 0;
  height: 0.1rem;
  background-color: #10418a;
}

.PlanVacacional_register-info__P3_zC {
  display: flex;
  color: #989a9e;
}

.PlanVacacional_register-info__P3_zC img {
  height: 100vh;
}

.PlanVacacional_player-wrapper__2qj3P > div {
  display: flex;
  justify-content: center;
  margin: auto;
}

.PlanVacacional_images__3cxQe {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.PlanVacacional_images__3cxQe > img:nth-child(2) {
  height: 100px;
}

.PlanVacacional_images__3cxQe > img:nth-child(3) {
  height: 250px;
}

.PlanVacacional_images__3cxQe > img {
  height: 150px;
}

.CategoryChallenge_category-challenge__3acGW {
  max-width: 80%;
}

.CategoryChallenge_title__3w3cd {
  color: #1e4266;
  text-align: center;
}

.CategoryChallenge_description__3Oqlg {
  text-align: justify;
  margin-top: 2rem;
}
.CategoryChallenge_bullets__17YJs {
  font-size: 1.25rem;
  text-align: justify;
}

.CategoryChallenge_subtitle__1mBDp {
  color: #1e4266;
  text-align: left;
}

.CategoryResult_container__1eK_M {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px;
  gap: 25px;
  justify-content: center;
  margin-bottom: 1rem;
}

.CategoryResult_category__DFU-d {
  border: 1px solid #244b91;
  border-radius: 10px;
  padding: 1rem;
  text-align: center;
}

.CategoryResult_category__DFU-d > h3 {
  color: #244b91;
  font-size: 1.75rem;
}

.CategoryResult_category__DFU-d li {
  list-style: none;
  font-size: 1.5rem;
}

.CategorySelection_central-title__3mrzT {
  margin: 2rem 0;
}

.CategorySelection_container__1hx1X {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin: 0 1rem;
  grid-gap: 2rem;
  gap: 2rem;
  margin-bottom: 0;
}

.CategorySelection_selectGroup__3-vXC {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

@media screen and (max-width: 550px) {
  .CategorySelection_selectGroup__3-vXC {
    flex-direction: column;
  }
}

.CategorySelection_selectGroup__3-vXC > select {
  padding: 0.25 rem;
  border: 1px solid #1e4266;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
}
.CategorySelection_selectGroup__3-vXC > button {
  background-color: #244b91;
  color: white;
  border: 1px solid #244b91;
  border-radius: 0.5rem;
  cursor: pointer;
}
.CategorySelection_selectGroup__3-vXC > button:hover {
  background-color: white;
  border: 1px solid #244b91;
  color: #244b91;
  border-radius: 0.5rem;
}

.CategorySelection_form_link__3D6-Y {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.75rem;
  align-items: center;
  color: white;
  background-color: #244b91;
  border: 1px solid #244b91;
  border-radius: 50%;
  height: 12rem;
  width: 12rem;
}
small {
  font-size: 0.75rem;
}

.CategorySelection_form_link__3D6-Y:hover {
  text-decoration: none;
  background-color: white;
  color: #244b91;
}

.CategorySelection_result__1zMKG {
  display: flex;
  margin: auto;
  background-color: #244b91;
  border-radius: 0.5rem;
}

.CategorySelection_no_category__2z8di {
  display: flex;
  margin: auto;
  background-color: #244b91;
  border-radius: 0.5rem;
  color: white;
  padding: 1rem;
}

a:hover {
  text-decoration: none;
}

.CategorySelection_notes__CWnN9 {
  text-align: justify;
  margin: 0;
}

.CategorySelection_notes__CWnN9 h4 {
  color: #1e4266;
}

.CategorySelection_cta_ticket__mpngG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 1rem 0;
}

.CategorySelection_ticket_button__1rexQ {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  background-color: #244b91;
  color: white;
  border: 1px solid #244b91;
  border-radius: 2rem;
  cursor: pointer;
  padding: 1rem;
  font-weight: bold;
}

.CategorySelection_ticket_button__1rexQ > p {
  margin: 0;
}

.CategorySelection_ticket_button__1rexQ > i {
  font-size: 2.5rem;
}

/* .fa-ticket-alt{
  font-size: 2rem;
} */

.CategorySelection_ticket_button__1rexQ:hover {
  background-color: white;
  border: 1px solid #244b91;
  color: #244b91;
}

.CategorySelection_note__2XMu2 {
  margin: 1rem;
  font-size: 1rem;
  text-align: center;
}

